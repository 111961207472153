import { template as template_4a1b7c7860fd497780c8589075bf7ccd } from "@ember/template-compiler";
const FKControlMenuContainer = template_4a1b7c7860fd497780c8589075bf7ccd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
