import { template as template_d20e69345f7440ea8de5e2df1da2e981 } from "@ember/template-compiler";
const WelcomeHeader = template_d20e69345f7440ea8de5e2df1da2e981(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
