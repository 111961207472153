import { template as template_825e11df9a734bc993c6d11c27a93630 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_825e11df9a734bc993c6d11c27a93630(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
