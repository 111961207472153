import { template as template_c4ded6ad3cbf49b3880b7eabac2d921a } from "@ember/template-compiler";
const SidebarSectionMessage = template_c4ded6ad3cbf49b3880b7eabac2d921a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
