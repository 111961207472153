import { template as template_5d00f67b0b36422d9dc9d6a6f6230834 } from "@ember/template-compiler";
const FKLabel = template_5d00f67b0b36422d9dc9d6a6f6230834(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
